import { client } from './http';

export async function sortPosts(userID = null, slug, next) {
  try {
    const { data } = await client.post(`/api/library/sortPosts`, {
      sentry: { username: slug, id: userID },
      userID: userID,
      slug: slug,
      next: next,
    });
    if (!data.success) {
      throw new Error('failed to sort posts');
    }
    return { posts: data.posts ? data.posts : [], next: data.next };
  } catch (err) {
    console.error(err);
    return false;
  }
}

export async function getDrafts(authToken, sentry) {
  let returnedPosts = [];
  let posts = await getDraftsWithLimit(authToken, sentry);
  returnedPosts = returnedPosts.concat(posts.posts);

  while (posts.next || posts.threadNext) {
    posts = await getDraftsWithLimit(
      authToken,
      sentry,
      posts.next,
      posts.threadNext,
    );
    returnedPosts = returnedPosts.concat(posts.posts);
  }

  return returnedPosts.reverse();
}

async function getDraftsWithLimit(
  authToken,
  sentry,
  next = null,
  threadNext = null,
) {
  try {
    const { data } = await client.post(`/api/library/get-drafts`, {
      sentry: sentry,
      authToken: authToken,
      next: next,
      threadNext: threadNext,
    });
    if (!data.success) {
      throw new Error('failed to fetch drafts');
    }

    return {
      posts: data.posts,
      next: data.next,
    };
  } catch (err) {
    console.error(err);
    return {
      posts: [],
      next: null,
    };
  }
}
